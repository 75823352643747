<template>
  <div>
    <!-- Page Title Start -->
    <div class="page-title title-bg-10">
      <div class="container">
        <div class="title-text text-center">
          <h2>Contact Us</h2>

        </div>
      </div>
    </div>
    <!-- Page Title End -->

    <!-- Contact Card Section Start -->
    <div class="contact-card-section pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="contact-card">
              <i class="icofont-phone" />
              <a href="tel:+22995066771">+229 95066771</a>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="contact-card">
              <i class="icofont-ui-message" />
              <a href="mailto:contact@mameribj.com">contact@mameribj.com</a>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div class="contact-card">
              <i class="icofont-location-pin" />
              <ul>
                <li>BP:556, Abomey-calavi, Bénin</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Card Section End -->

    <!-- Contact Section Start -->
    <section class="contact-section contact-style-two pb-100">
      <div class="container">
        <div class="section-title text-center">
          <h2>Posez vos Question</h2>
          <p>Posez vos préoccupations et nous vous apporterons plus d'explications aussitôt que possible</p>
        </div>

        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="contact-area">
              <form id="contactForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="name"
                        type="text"
                        name="name"
                        class="form-control"
                        required
                        data-error="Please enter your name"
                        placeholder="Nom complet"
                      >
                      <div class="help-block with-errors" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        class="form-control"
                        required
                        data-error="Please enter your email"
                        placeholder="Addresse Email"
                      >
                      <div class="help-block with-errors" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="number"
                        type="number"
                        name="number"
                        class="form-control"
                        required
                        data-error="Please enter your number"
                        placeholder="Téléphone"
                      >
                      <div class="help-block with-errors" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        id="subject"
                        type="text"
                        name="subject"
                        class="form-control"
                        required
                        data-error="Please enter your subject"
                        placeholder="Votre sujet"
                      >
                      <div class="help-block with-errors" />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <textarea
                        id="message"
                        name="message"
                        class="message-field"
                        cols="30"
                        rows="5"
                        required
                        data-error="Please type your message"
                        placeholder="Votre Message"
                      />
                      <div class="help-block with-errors" />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 text-center">
                    <button
                      type="submit"
                      class="default-btn contact-btn"
                    >
                      Envoyer
                    </button>
                    <div
                      id="msgSubmit"
                      class="h3 alert-text text-center hidden"
                    />
                    <div class="clearfix" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact Section End -->
  </div>

</template>

<script>
export default {

}

</script>
